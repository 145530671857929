import React from "react"

import Green from "./green";


const IndexPage = () => {
  return (
    <Green />)
}

export default IndexPage
